import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import logo1 from "../img/positive_life_logo.png"
import logo2 from "../img/positive-health-logo.png"
import logo3 from "../img/positive-health-logo.png"
import logo4 from "../img/health_and_life_logo.png"
import BackgroundImage from 'gatsby-background-image'
import SiteInfo from "./siteinformation"

const Footer = ({ className }) => (
  <StaticQuery
    query={graphql`
      {
          img: allFile(filter: {sourceInstanceName: {eq: "img"}, name: {eq: "footer-bg"}}) {
            nodes {
              name
              childImageSharp {
                fluid {
                  src

                }
              }
            }
          }
        }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.img.nodes[0].childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <footer className="footer-section">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-lg-6">
                          <div className="footer-logo">
                              <img src={getHeaderLogo()} alt="" />
                          </div>

                      </div>
                      <div className="col-lg-12 text-lg-right">
                          <ul className="footer-menu">
                              <li><a href="/">Home</a></li>
                              <li><a href="/earnings/">Earnings</a></li>
                              <li><a href="/privacy/">Privacy</a></li>
                              <li><a href="/terms/">Terms and Conditions</a></li>
                              <li><a href="/dmca/">DMCA</a></li>
                            <li><a href="mailto:admin@healthandlife.link">Contact</a></li>
                          </ul>
                          <p
                              className="copyright">
                              Copyright &copy;2020&nbsp;
                              <script>document.write(new Date().getFullYear());</script>
                              All rights reserved | This template is made with <i className="fa fa-heart-o"
                                                                                  aria-hidden="true"></i> by <a
                              href="https://colorlib.com" target="_blank">Colorlib</a>

                          </p>
                      </div>
                  </div>
              </div>
          </footer>
   {/*       <footer className="footer-section set-bg">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6">
                  <div className="footer-logo">
                    <img src="img/logo.png" alt="" />
                  </div>
                  <div className="footer-social">
                    <a href="#"><i className="fa fa-pinterest"></i></a>
                    <a href="#"><i className="fa fa-facebook"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a>
                    <a href="#"><i className="fa fa-dribbble"></i></a>
                    <a href="#"><i className="fa fa-behance"></i></a>
                    <a href="#"><i className="fa fa-linkedin"></i></a>
                  </div>
                </div>
                <div className="col-lg-12 text-lg-right">
                  <ul className="footer-menu">
                    <li><a href="#">Home</a></li>
                    <li><a href="#">Features</a></li>
                    <li><a href="#">Receipies</a></li>
                    <li><a href="#">Reviews</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                  <p
                    className="copyright">
                    Copyright &copy;
                    <script>document.write(new Date().getFullYear());</script>
                    All rights reserved | This template is made with <i className="fa fa-heart-o"
                                                                        aria-hidden="true"></i> by <a
                    href="https://colorlib.com" target="_blank">Colorlib</a>

                  </p>
                </div>
              </div>
            </div>
          </footer>*/}
        </BackgroundImage>
      )
    }}
  />
)

function getHeaderLogo() {
    var site = new SiteInfo().getSiteNumber();

    if (site == 2) {
        return logo2;
    }
    if (site == 3) {
        return logo3;
    }
    if (site == 4) {
        return logo4;
    }

    return logo1;
}

const StyledFooter = styled(Footer)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`


export default StyledFooter



